import React, { createContext, useState } from "react";
export const ConfirmContext = createContext();

const ConfirmContextProvider = ({ children }) => {
  const [confirm, setConfirm] = useState({
    prompt: "",
    isOpen: false,
    proceed: null,
    cancel: null,
    flag:true,
    confirmType:true,
    notify:false,
  });
  
  return (
    <ConfirmContext.Provider value={[confirm, setConfirm]}>
      {children}
    </ConfirmContext.Provider>
 );
};
export default ConfirmContextProvider;