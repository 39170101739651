import React, { useState, useEffect } from "react";
import { Route } from "react-router-dom";
import { HOME,RESTRICTED_URLS } from "../../../URL";
import userData from "../../ServerFunctions/userData/userData";
const PrivateRoute = ({ component: Component, ...rest }) => {
    const [allow, setAllow] = useState(null);
    const [restriced, setRestriced] = useState(null);
    useEffect(() => {
      checkMetaMaskConnection()
    }, []);
    const checkMetaMaskConnection = async () => {
      if (userData) {
        setAllow(true);
      } else {
        setAllow(false);
      }
      let checkURL = rest.location.pathname;
      
      if (!RESTRICTED_URLS.includes(checkURL)) {
        setRestriced(false);
      } else {
        setRestriced(true);
      }
    };
    const callURL = () => {
      window.location.href = HOME;
    };
    return (
      <>
        {allow !== null ? (
          <Route
            {...rest}
            render={(props) =>
              restriced ? (
                allow ? (
                  <Component {...props} />
                ) : (
                  callURL()
                )
              ) : (
                <Component {...props} />
              )
            }
          />
        ) : null}
      </>
    );
  };
  export default PrivateRoute;