import React from "react"
import classes from './PageLoader.module.css'
import loader_icon from '../../../assets/images/loader_icon.gif'
export default function PageLoader(){
    return (
        <div className={classes.OuterContainer}>
            <div className={classes.InnerContainer}>
                <div className={classes.MainContainer} id='loaderId'>
                    <img src={loader_icon} alt='loader'/>
                </div>
            </div>
        </div>
    )
}