import classes from './App.module.css';
import { useEffect,Suspense, lazy } from 'react';
import { BrowserRouter,Switch} from "react-router-dom";
// import HomePage from './components/PrimaryComponent/HomePage/HomePage';
// import ChatComponent from './components/PrimaryComponent/ChatComponent/ChatComponent';
// import DatasetComponent from './components/PrimaryComponent/DatasetComponent/DatasetComponent';
// import ModelComponent from './components/PrimaryComponent/ModelComponent/ModelComponent';
// import BetaLogin from './components/PrimaryComponent/BetaLogin/BetaLogin';
// import Login from './components/CommonComponents/Login/Login';
import ConfirmContextProvider from './components/CustomComponent/PopUps/ContextAPI/ConfirmContextProvider';
// import ConfirmModal from './components/CustomComponent/PopUps/ContextAPI/ConfirmModal';
import PrivateRoute from './components/CustomComponent/PrivateRoute/PrivateRoute';
// import BRT from "weboasis-bug-reporting-tool-wa";
import google from './assets/images/brt-logo.png'
// import OAuthRedirect from './components/CustomComponent/GoogleDocsExtractor/OauthRedirect';
// import ContactSales from './components/PrimaryComponent/ContactSales/ContactSales';
import { HOME,CHAT,DATASET,MODEL,ADMIN,PROFILE,RESET_PASSWORD, REDIRECT,SALES, AI_PAGE } from './URL';
import PageLoader from './components/Loaders/PageLoader/PageLoader';
// import Profile from './components/PrimaryComponent/Profile/Profile';
// import ApiIntegraitonComponent from './components/PrimaryComponent/ApiIntegrationComponent/ApiIntegraitonComponent';
// const ChatComponent=lazy(()=>import('./components/PrimaryComponent/ChatComponent/ChatComponent'))
const ChatComponent=lazy(()=>import('./components/PrimaryComponent/ChatComponent/ChatComponent'))
const DatasetComponent=lazy(()=>import('./components/PrimaryComponent/DatasetComponent/DatasetComponent'))
const ModelComponent=lazy(()=>import('./components/PrimaryComponent/ModelComponent/ModelComponent'))
const Profile=lazy(()=>import('./components/PrimaryComponent/Profile/Profile'))
const Login=lazy(()=>import('./components/CommonComponents/Login/Login'))
const HomePage=lazy(()=>import('./components/PrimaryComponent/HomePage/HomePage'))
const ContactSales=lazy(()=>import('./components/PrimaryComponent/ContactSales/ContactSales'))
const ApiIntegraitonComponent=lazy(()=>import('./components/PrimaryComponent/ApiIntegrationComponent/ApiIntegraitonComponent'))
const ConfirmModal=lazy(()=>import('./components/CustomComponent/PopUps/ContextAPI/ConfirmModal'))
const OAuthRedirect=lazy(()=>import('./components/CustomComponent/GoogleDocsExtractor/OauthRedirect'))
const BRT =lazy(()=>import('weboasis-bug-reporting-tool-wa'))
const IGNORE_THEME=[HOME, AI_PAGE]
function App() {

  useEffect(()=>{
    // if(window.innerWidth>1024){
    const themePick=localStorage.getItem("theme")
    console.log(window.location.pathname,"route")
    const route=window.location.pathname
    if(!IGNORE_THEME.includes(route)){
    if(themePick){
      if(themePick==="DARK"){
        localStorage.setItem("theme","DARK")
        document.documentElement.setAttribute("data-theme", "dark");
      }
      if(themePick==="LIGHT"){
        localStorage.setItem("theme","LIGHT")
        document.documentElement.setAttribute("data-theme", "light");
      }
    }
    else{
    //   if (window.matchMedia && window.matchMedia('(prefers-color-scheme: dark)').matches) {
    //     // user has dark theme enabled
    //     localStorage.setItem("theme","DARK")
    //     document.documentElement.setAttribute("data-theme", "dark");
    // } else {
    //     // user has light theme enabled
    //     localStorage.setItem("theme","LIGHT")
    //     document.documentElement.setAttribute("data-theme", "light");
    // }
    localStorage.setItem("theme","DARK")
    document.documentElement.setAttribute("data-theme", "dark");
    }
  }
  },[])
  const props={
    styling:{
     background:['#F8F8F8','#F8F8F8'], //gradient and base color
     themeColor:['#B79BFF'], // like its pink right now
     fontTheme:["#4E4E4E"],
     black:false,
     headerBg:['#B79BFF'],
      darkBg:false,
     imageFilter:'none',
     logo:{
      img:google,
      'width':'52px',
      'height':'52px'
     } // logo of website
    },
    credentials:{
     key:"e8d3719cc12983ffa1d296825d08e188",
     token:"ATTAd0c98bfaab98fefb55ab042c4301fc541bd3ebf67c9888c2143efdd202d4e470574A7435",
     idList: "64af7d21a1ece449674b35d5",
     labels :[{
           "name": "Major",
           "code": "64af7d118b05e865ec97e34e"
         },
         {
           "name": "Minor",
           "code": "64af7d118b05e865ec97e355"
         }], 
     email:true
    }
  }

  return (
    <div className={classes.MainAppContainer}>
      <div className={classes.BugReporting}>
        <Suspense>
       <BRT props={props}/>
       </Suspense>
       </div>
      <BrowserRouter>
      <Suspense fallback={<PageLoader/>}>
      <ConfirmContextProvider>
      <ConfirmModal/>
         <Switch>
           <PrivateRoute  exact path={REDIRECT} component={OAuthRedirect} />
           <PrivateRoute exact path={CHAT} component={ChatComponent}/>
           {/* <PrivateRoute exact path={DATASET} component={DatasetComponent}/> */}
           <PrivateRoute exact path={MODEL} component={ModelComponent}/>
           <PrivateRoute exact path={HOME} component={HomePage}/> 
           {/* <PrivateRoute exact path={ADMIN} component={BetaLogin}/> */}
           <PrivateRoute exact path={PROFILE} component={Profile}/>
           <PrivateRoute exact path={RESET_PASSWORD} component={Login}/>
           <PrivateRoute exact path={SALES} component={ContactSales}/>
           <PrivateRoute exact path={AI_PAGE} component={ApiIntegraitonComponent}/>
         </Switch>
      </ConfirmContextProvider>
      </Suspense>
       </BrowserRouter>
       {/* <iframe width="500px" height="600px" src=" https://1ea9-106-221-4-196.ngrok-free.app/ChatBot/index.html" style={{position:"fixed",right:"10px",bottom:"10px",border:"none",margin:"0px",padding:"0px",zIndex:"230000"}}></iframe> */}
    </div>
  );
}

export default App;
