import Cookies from 'js-cookie';

let userData = null;
let refresh_token = null;

function isJSON(str) {
  try{
    JSON.parse(str);
  }catch (e) {
    return false;
  }
  return true;
}

function checkAndLogoutUser() {
  // Check if the user token is undefined in local storage
  let userToken = localStorage.getItem('username');
  try{
    if(userToken){
      userToken = userToken.split(":::")[0]
      if ( userToken === 'undefined' || !isJSON(userToken)) {
        // alert("DELETING 1")
        localStorage.removeItem('username');
        return true
      }
    }
    // console.log(Cookies.get('loginTime'), "HEY COOKIE")
    if(!Cookies.get('loginTime')){
      if(process.env.REACT_APP_ENVIRONMENT === "development"){
        return false
      }
      // alert("DELETING 2")
      localStorage.removeItem('username')
      return true
    }
    return false
  }
  catch(err){
    console.log(err)
    return true
  }
}
if(!checkAndLogoutUser()){
  if (localStorage.getItem("username")) {
    const tokens = localStorage.getItem("username");
    // console.log(tokens, "TOKENS")
    userData = tokens.split(":::")[0];
    // console.log(userData, "UDDD")
    userData = JSON.parse(userData);
    refresh_token = tokens.split(":::")[1];
  }
}


export default userData;
export { refresh_token };