export const HOME="/"
export const CHAT="/chat"
export const DATASET="/fine-tune"
export const MODEL="/model"
export const ADMIN="/admin"
export const PROFILE="/profile"
export const REDIRECT="/oauth-redirect"
export const RESET_PASSWORD="/reset-password"
export const SALES="/contact"
export const AI_PAGE="/solutions"
export const RESTRICTED_URLS=[CHAT,DATASET,MODEL,PROFILE]